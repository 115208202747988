import clsx from "clsx";
import React from "react";
export type ButtonVariant = "dark" | "bordered" | "light" | "bordered-light" | "chunky-dark" | "chunky-bordered" | "boxy-dark" | "bordered-white";
type Props = {
  title: React.ReactNode;
  id?: string;
  variant: ButtonVariant;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  innerRef?: React.LegacyRef<HTMLButtonElement>;
  isSubmit?: boolean;
  icon?: React.ReactNode;
  iconURL?: string;
};
export type { Props as ButtonProps };
export function Button({
  className,
  id,
  title,
  variant,
  onClick,
  disabled = false,
  innerRef,
  isSubmit = false,
  icon,
  iconURL,
  ...props
}: Props) {
  const variantStyle = variants[variant];
  return <button {...props} id={id} type={isSubmit ? "submit" : "button"} disabled={disabled} onClick={onClick} ref={innerRef} className={clsx(["font-regular text-sm", "min-w-36 inline-flex cursor-pointer items-center justify-center text-center", className, variantStyle])} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {icon}
      {iconURL && <img src={iconURL} alt="" className="mr-2" />}
      {title}
    </button>;
}

// eslint-disable-next-line react/display-name
export const LinkButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  return <Button innerRef={ref} {...props} />;
});
export const Loading = () => <svg className="absolute right-3 h-8 w-8 animate-spin rounded-full bg-plum p-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="Loading" data-sentry-source-file="Button.tsx">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Button.tsx"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" data-sentry-element="path" data-sentry-source-file="Button.tsx"></path>
  </svg>;
const variants: Record<ButtonVariant, any> = {
  dark: "h-10 px-8 rounded-full text-beige-64 bg-plum hover:bg-plum-80 disabled:bg-transparent disabled:border-plum-40 disabled:border-2 disabled:text-plum-40 active:bg-plum",
  light: "h-10 px-8 rounded-full text-plum bg-white hover:opacity-80 disabled:bg-plum-16 disabled:text-plum-40 active:opacity-60 ",
  bordered: "h-10 px-8 rounded-full text-plum border-plum border hover:bg-plum-8 active:bg-plum-16  disabled:border-plum-40 disabled:bg-transparent disabled:text-plum-40",
  "bordered-light": "h-10 px-8 rounded-full text-beige-64 border-beige-64 border hover:bg-beige/10 active:bg-beige/20  disabled:opacity-40 disabled:hover:bg-transparent",
  "bordered-white": "h-10 px-8 rounded-full text-plum bg-white hover:opacity-80 border-plum-16 border disabled:bg-plum-16 disabled:text-plum-40 active:opacity-60 ",
  "chunky-dark": "w-full px-8  md:w-auto h-[50px] rounded-lg md:rounded-3xl text-beige-64 bg-plum hover:bg-plum-80 disabled:bg-transparent disabled:border-plum-40 disabled:border-2 disabled:text-plum-40 active:bg-plum ",
  "chunky-bordered": "w-full px-8  md:w-auto h-[50px] rounded-lg md:rounded-3xl text-plum border-plum border-2 bg-transparent hover:bg-plum-8 active:bg-plum-16 disabled:bg-transparent disabled:border-plum-40 disabled:border-2 disabled:text-plum-40 ",
  "boxy-dark": "text-beige-64 w-full h-12 rounded-lg px-2 text-white bg-plum hover:bg-plum-80 disabled:bg-plum-16 disabled:text-white active:bg-plum "
};