import Script from "next/script";
import React from "react";
import { LoadingAnimation } from "../LoadingAnimation";
import { HubSpotFormArgs } from "./types";
import clsx from "clsx";
interface Props extends HubSpotFormArgs {
  className?: string;
}
const DEFAULTS = {
  region: "na1",
  portalId: "44296560"
};
declare global {
  interface Window {
    hbspt: any;
  }
}
export const HubSpotForm: React.FC<Props> = args => {
  const {
    region,
    portalId,
    formId,
    className,
    sfdcCampaignId,
    hasMinHeight = true
  } = {
    ...DEFAULTS,
    ...args
  };
  const formHtmlId = "hubspot-form-" + formId;
  const createForm = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region,
        portalId,
        formId,
        target: `#${formHtmlId}`,
        sfdcCampaignId
      });
    }
  };
  return <div className={className} data-sentry-component="HubSpotForm" data-sentry-source-file="HubSpotForm.tsx">
      <div id={formHtmlId} className={clsx("flex flex-col items-center justify-center hubspot-form", {
      "min-h-[400px]": hasMinHeight
    })}>
        <LoadingAnimation data-sentry-element="LoadingAnimation" data-sentry-source-file="HubSpotForm.tsx" />
      </div>
      <Script onReady={createForm} src="https://js.hsforms.net/forms/v2.js" data-sentry-element="Script" data-sentry-source-file="HubSpotForm.tsx" />
    </div>;
};