import clsx from "clsx";

// Animation Def:
// Total frames = 107 (30 in, 22 hold, 30 out, 25 hold)
// Stagger = 25 frames
// FPS = 60
// Duration = 107 / 60 = 1783ms
// Keyframes - 0, 28%, 48%, 76%, 100%

export const LoadingAnimation = ({
  className
}: {
  className?: string;
}) => {
  return <div className={clsx("relative -left-1 flex h-2 gap-2 opacity-[64%]", className)} data-sentry-component="LoadingAnimation" data-sentry-source-file="LoadingAnimation.tsx">
      <span className="block h-2 w-2 animate-dot rounded-full bg-current opacity-0 " style={{
      animationDelay: "-1783ms"
    }}></span>
      <span className="block h-2 w-2 animate-dot rounded-full bg-current opacity-0" style={{
      animationDelay: "-1283ms"
    }}></span>
      <span className="block h-2 w-2 animate-dot rounded-full bg-current opacity-0" style={{
      animationDelay: "855ms"
    }}></span>
    </div>;
};