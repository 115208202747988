import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { HubSpotForm } from "./HubSpot/HubSpotForm";
interface Props {
  className?: string;
}
const Newsletter = ({
  className
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (modalIsOpen) {
      inputRef.current?.focus?.();
    }
  }, [modalIsOpen]);
  const open = () => {
    setModalIsOpen(true);
  };
  const close = () => {
    setModalIsOpen(false);
  };
  return <>
      <Button variant="dark" title="Subscribe to Our Newsletter" className={clsx("mb-6 xl:mb-0 xl:mr-4", className)} onClick={open} id="newsletter__subscribe_open_modal" data-sentry-element="Button" data-sentry-source-file="Newsletter.tsx" />
      <Modal visible={modalIsOpen} onClose={close} title={"newsletter-subscription"} data-sentry-element="Modal" data-sentry-source-file="Newsletter.tsx">
        <div className="mx-auto flex max-w-sm flex-col py-20">
          <h2 className="text-e4 text-center" id="modal-headline">
            Come along
            <br /> with Empathy.
          </h2>
          <p className="text-body mt-4 mb-12 text-center">
            Follow our journey as we bring technology and compassion to the
            world of loss
          </p>
          <HubSpotForm formId="ce5de312-9f6d-4a8a-8b61-8f2a1633893d" sfdcCampaignId="701Un00000CEPxKIAX" hasMinHeight={false} data-sentry-element="HubSpotForm" data-sentry-source-file="Newsletter.tsx" />
        </div>
      </Modal>
    </>;
};
export const Loading = () => {
  return <svg className="absolute right-3 h-8 w-8 animate-spin rounded-full bg-plum p-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="Loading" data-sentry-source-file="Newsletter.tsx">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Newsletter.tsx"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" data-sentry-element="path" data-sentry-source-file="Newsletter.tsx"></path>
    </svg>;
};
export default Newsletter;